require('~/assets/cookieconsent/cookieconsent.js')

export default ({ app }, inject) => {
  const cc = window.initCookieConsent()
  const i18n = app.i18n

  const r = /.*\.([^.]*[^0-9][^.]*\.[^.]*[^.0-9][^.]*$)/
  const rootDomain = window.location.hostname.replace(r, '$1')

  window.dataLayer = window.dataLayer || []
  function gtag () {
    window.dataLayer.push(arguments)
  }

  const privacyStatementUrl = app.$config.urls.privacyStatement.replace('{lang}', i18n.locale)

  cc.run({
    cookie_expiration: 365,
    cookie_domain: `.${rootDomain}`,
    current_lang: 'i18n',
    delay: 2500,
    onAccept () {
      if (cc.allowedCategory('analytics_cookies')) {
        gtag('consent', 'update', { analytics_storage: 'granted' })
        gtag('set', 'url_passthrough', false)
      }
    },
    onChange () {
      if (!cc.allowedCategory('analytics_cookies')) {
        gtag('consent', 'update', { analytics_storage: 'denied' })
        gtag('set', 'url_passthrough', true)
      } else {
        gtag('consent', 'update', { analytics_storage: 'granted' })
        gtag('set', 'url_passthrough', false)
      }
    },

    languages: {
      i18n: {
        consent_modal: {
          title: i18n.t('cookie_consent.consent_modal.title'),
          description: i18n.t('cookie_consent.consent_modal.description'),
          primary_btn: {
            text: i18n.t('cookie_consent.consent_modal.primary_btn'),
            role: 'accept_all' // 'accept_selected' or 'accept_all'
          },
          secondary_btn: {
            text: i18n.t('cookie_consent.consent_modal.secondary_btn'),
            role: 'settings' // 'settings' or 'accept_necessary'
          }
        },
        settings_modal: {
          title: i18n.t('cookie_consent.settings_modal.title'),
          save_settings_btn: i18n.t('cookie_consent.settings_modal.save_settings_btn'),
          accept_all_btn: i18n.t('cookie_consent.settings_modal.accept_all_btn'),
          cookie_table_headers: [
            { col1: i18n.t('cookie_consent.settings_modal.cookie_table_headers.col1') },
            { col3: i18n.t('cookie_consent.settings_modal.cookie_table_headers.col3') },
            { col4: i18n.t('cookie_consent.settings_modal.cookie_table_headers.col4') }
          ],
          blocks: [
            {
              title: i18n.t('cookie_consent.settings_modal.blocks.header.title'),
              description: i18n.t('cookie_consent.settings_modal.blocks.header.description',
                [`<a aria-label="Privacybeleid" class="cc-link" target="_blank" rel="noopener" href="${privacyStatementUrl}">`])
            }, {
              title: i18n.t('cookie_consent.settings_modal.blocks.necessary_cookies.title'),
              description: i18n.t('cookie_consent.settings_modal.blocks.necessary_cookies.description'),
              toggle: {
                value: 'necessary_cookies',
                enabled: true,
                readonly: true
              },
              cookie_table: [
                {
                  col1: 'cc_cookie',
                  col3: `1 ${i18n.t('cookie_consent.settings_modal.year')}`,
                  col4: i18n.t('cookie_consent.settings_modal.cookie_preferences')
                },
                {
                  col1: 'i18n_redirected',
                  col3: `1 ${i18n.t('cookie_consent.settings_modal.year')}`,
                  col4: i18n.t('cookie_consent.settings_modal.language_preferences')
                },
                {
                  col1: 'auth0.is.authenticated',
                  col3: `1 ${i18n.t('cookie_consent.settings_modal.day')}`,
                  col4: i18n.t('cookie_consent.settings_modal.auth_info')
                },
                {
                  col1: '_legacy_auth0.is.authenticated',
                  col3: `1 ${i18n.t('cookie_consent.settings_modal.day')}`,
                  col4: i18n.t('cookie_consent.settings_modal.auth_info')
                }
              ]
            }, {
              title: i18n.t('cookie_consent.settings_modal.blocks.analytical_cookies.title'),
              description: i18n.t('cookie_consent.settings_modal.blocks.analytical_cookies.description'),
              toggle: {
                value: 'analytical_cookies',
                enabled: false,
                readonly: false
              },
              cookie_table: [
                {
                  col1: '_ga',
                  col3: `2 ${i18n.t('cookie_consent.settings_modal.years')}`,
                  col4: 'Google Analytics'
                },
                {
                  col1: '_gid',
                  col3: `1 ${i18n.t('cookie_consent.settings_modal.day')}`,
                  col4: 'Google Analytics'
                }
              ]
            }
          ]
        }
      }
    }
  })
  inject('cookieconsent', cc)
}
